Element.prototype.escamote = function (handle = null) {
    this.style.display = 'block';
    var cont = document.createElement('div');
    this.parentElement.insertBefore(cont, this);
    cont.append(this);
    cont.style.width = '100%';
    cont.style.height = 0;
    cont.style.transition = 'height ease-out 200ms';
    cont.append(this);
    cont.style.overflow = 'hidden';
    cont.style.position = 'absolute';
    this.style.position = 'absolute';
    this.style.left = '0';
    this.style.right = '0';
    this.style.bottom = '0';
    if (!handle) handle = cont.parentElement.querySelectorAll('a')[0];
    var $this = this;
    handle.parentElement.addEventListener('mouseover', function (e) {
        e.stopPropagation();
        e.preventDefault();
        cont.style.height = ($this.offsetHeight - 1) + 'px';
    });
    handle.parentElement.addEventListener('mouseout', function () {
        cont.style.height = 0;
    });
}

// //////////////////////////////////////////////////////////////////////////

Element.prototype.offCanvas = function () {
   const $this = this;
   const menu = this.querySelector('.off-canvas-nav-cont');
   const btnClose = document.getElementById('off-canvas-close');
   btnClose.addEventListener('click', function(e){ e.stopPropagation(); e.preventDefault(); $this.close()});
   menu.addEventListener('mouseup', function(e){ e.stopPropagation();});
   const funcClose = function (e){$this.close();}
   this.open = function(){
       btnClose.style.display = 'block';
       let h = menu.offsetHeight;
       if(h > window.innerHeight) {
           h = window.innerHeight;
           $this.classList.add('scrollable');
       }
       $this.style.height = h + 'px';
       window.addEventListener('mouseup', funcClose);
   }
   this.close = function(){
       btnClose.style.display = 'none';
       $this.style.height = 0;
       $this.classList.remove('scrollable');
       window.removeEventListener('click', funcClose);
   }
   window.addEventListener('resize', this.close);
   return this;
}



document.addEventListener("DOMContentLoaded", function() {
    var ul = document.querySelectorAll('#main-nav > ul > li > ul');
    ul.forEach(el => el.escamote());
    const offCanvas = document.getElementById('off-canvas-nav').offCanvas();
    const hamburger = document.getElementById('hamburger');
    hamburger.addEventListener('click', function(e){
        e.preventDefault();
        e.stopPropagation();
        offCanvas.open();
    });
});

